import { useRef, useState, useEffect } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import { getCookieConsentValue } from 'react-cookie-consent';
import CookiesNotice from './components/CookiesNotice';
import Listen from './components/Listen';
import ThreeCanvas from './components/ThreeCanvas';
import CanvasGraph from './components/CanvasGraph';
import Chatbot from './components/Chatbot';
import * as _ from 'underscore';
import Transcript from './components/Transcript';

const Content = (props) => {

  const { windowWidth, windowHeight, cookiesAreAccepted, setCookiesAreAccepted, handleMouseMoveThrottled } = props;

  return (
    <div
      className={`wrapper`}
      style={{
        width: windowWidth + 'px',
        height: windowHeight + 'px'
      }}
      onMouseMove={handleMouseMoveThrottled}
    >
      <p
        style={{
          position: 'absolute',
          zIndex: 999,
          left: '50px',
          margin: '20px 0',
          color: 'green'
        }}
      >websocket connection status:: {props.connectionStatus}</p>

      <div
        className="three-canvas__wrapper"
        style={{
          width: windowWidth + 'px',
          height: windowHeight + 'px'
        }}
      >
        {
          // 1 === 2 &&
          <ThreeCanvas {...props} />
        }
      </div>
      <main className={`content container-fluid`} style={{ padding: '50px' }}>
        <div className="row row--main">
          <div className="col-xxs-12 col-sm-6 col-md-9">
            <Transcript {...props} />
          </div>
          <div className="col-xxs-12 col-sm-6 col-md-3 column--controls">
            <Listen {...props} />
            <CanvasGraph {...props} />
            <Chatbot {...props} />
          </div>
        </div>
      </main>
      {
        cookiesAreAccepted === false &&
        <CookiesNotice handleAcceptCookies={() => setCookiesAreAccepted(true)} />
      }
    </div>
  )
}

const App = (props) => {

  const [socketUrl] = useState('ws://ahnen.in/ws.io/');
  const websocket = useRef();
  const [readyState, setReadyState] = useState(-1);
  const [connectionStatus, setConnectionStatus] = useState('closed');
  const [messageHistory, setMessageHistory] = useState([]);
  const [transcriptHistory, setTranscriptHistory] = useState([]);

  useEffect(() => {

    websocket.current = new WebSocket(socketUrl);

    websocket.current.onopen = () => {
      console.log("Server Connection Established", 3);
      const greeting = "new visitor";
      websocket.current.send(greeting);
      setReadyState(1);
      setConnectionStatus('open');
    };

    websocket.current.onclose = (e) => {
      console.log(e);
      setConnectionStatus('closed');
    }
  }, [socketUrl]);

  useEffect(() => {

    const handleReceiveMessage = (event) => {
      const parcel = JSON.parse(event.data);

      console.log("Unpacked message: ", parcel);
      switch (parcel.msg) {
        case "handshake":
          if(parcel.sign === "connection successful") {
          }
          break;
        case "chat":
          setMessageHistory([
            ...messageHistory,
            {
              type: 'received',
              text: parcel.text,
              voice: parcel.voice
            }
          ])
          // sendMessage("chat", parcel.text, 1000 + (Math.random() * 2000));
          break;
        case "text":
          console.log(parcel);
          setTranscriptHistory([
            ...transcriptHistory,
            {
              type: 'received',
              text: parcel.text
            }
          ]);
          break;
        case "sim":
          switch(parcel.sim_id) {
            case "lv1":
              // const data = parcel.data
              // const prev = parcel.prev
              console.log(parcel)
              // const t = parseInt(data.t * 10) % document.body.clientWidth;
              // sim("lv1", "datatxy", [t, data.a * 10, data.b * 10], [t, prev.a * 10, prev.b * 10]);
            break;
            default:
              throw new Error("Unrecognized simulation: " + parcel.sim_id)
          }
          break;
        default:
          console.log('oops')
          break;
      }
    }

    websocket.current.addEventListener('message', handleReceiveMessage);

    return () => {
      websocket.current.removeEventListener('message', handleReceiveMessage);
    }

  }, [messageHistory, transcriptHistory]);

  // const {
  //   sendMessage,
  //   lastMessage,
  //   readyState,
  // } = useWebSocket(
  //   socketUrl,
  //   {
  //     onOpen: () => {
  //       const greeting = "new visitor";
  //       sendMessage(greeting);
  //     },
  //     onClose: (e) => console.log(e),
  //     //Will attempt to reconnect on all close events, such as server shutting down
  //     shouldReconnect: (closeEvent) => true,
  //     onMessage: (message) => handleReceiveMessage(message)
  //   }
  // );

  const [windowDimensions, setWindowDimensions] = useState({
    windowHeight: window.innerHeight,
    windowWidth: window.innerWidth
  });
  const [isLoading, setIsLoading] = useState(true);
  const [cookiesAreAccepted, setCookiesAreAccepted] = useState(getCookieConsentValue() ? true : false);
  const [isPlaying, setIsPlaying] = useState(false);
  const [mousePosition, setMousePosition] = useState({ x: 0, y: 0 });
  const [isTouchScreen, setIsTouchScreen] = useState(false);

  const navigate = useNavigate();
  const location = useLocation();

  const audio = useRef();
  const audioCtx = useRef();
  const analyser = useRef();
  const [bufferLength, setBufferLength] = useState(0);
  const dataArray = useRef();

  const canvas = useRef();
  const raf = useRef();

  const handleStreamStarted = () => {
    if (audio.current) {
      if (isPlaying !== true) {
        audio.current.play()
        .then(() => {
          setIsPlaying(true);

          if (audio.current?.src && canvas.current) {
            audioCtx.current = new (window.AudioContext || window.webkitAudioContext)();
            analyser.current = audioCtx.current.createAnalyser();
            var stream = audio.current.captureStream();
            var source = audioCtx.current.createMediaStreamSource(stream);
            source.connect(analyser.current);
            analyser.current.fftSize = 2048;
            const newBufferLength = analyser.current.frequencyBinCount;
            setBufferLength(newBufferLength);
            dataArray.current = new Uint8Array(newBufferLength);
            analyser.current.getByteTimeDomainData(dataArray.current);

            const canvasCtx = canvas.current.getContext('2d');
            canvasCtx.clearRect(0, 0, canvas.current.width, canvas.current.height);

            const draw = () => {
              raf.current = requestAnimationFrame(draw);
              if (dataArray.current) {
                analyser.current.getByteTimeDomainData(dataArray.current);
                canvasCtx.clearRect(0, 0, canvas.current.width, canvas.current.height);
                canvasCtx.lineWidth = 1;
                canvasCtx.strokeStyle = 'rgb(0, 0, 0)';
                canvasCtx.beginPath();
                const sliceWidth = canvas.current.width * 1.0 / newBufferLength;
                let x = 0;

                for (let i = 0; i < newBufferLength; i++) {

                  const v = dataArray.current[i] / 128.0;
                  const y = v * canvas.current.height / 2;

                  if (i === 0) {
                    canvasCtx.moveTo(x, y);
                  } else {
                    canvasCtx.lineTo(x, y);
                  }

                  x += sliceWidth;
                }

                canvasCtx.lineTo(canvas.current.width, canvas.current.height / 2);
                canvasCtx.stroke();
              }
            }

            draw();

          }
        })
        .catch(error => {
          setIsPlaying(false);
        });
      } else {
        cancelAnimationFrame(raf.current);
        audio.current.pause();
        setIsPlaying(false);
      }
    }
  }

  useEffect(() => {
    if (location.pathname !== '/') {
      setIsLoading(false);
    }
  }, [location.pathname]);

  useEffect(() => {
  }, []);

  useEffect(() => {
    const handleWindowResize = () => {
      setWindowDimensions({
        windowHeight: window.innerHeight,
        windowWidth: window.innerWidth
      });
    }
    handleWindowResize();
    window.addEventListener('resize', handleWindowResize);

    return () => {
      window.removeEventListener('resize', handleWindowResize);
    }
  }, []);

  const handleMouseMove = (e) => {
    let x, y;
    if (e.clientX && e.clientY) {
      x = e.clientX;
      y = e.clientY;
    }
    if (e.touches) {
      if (isTouchScreen !== true) {
        setIsTouchScreen(true);
      }
      if (e.touches[0]) {
        x = e.touches[0].clientX;
        y = e.touches[0].clientY;
      }
    } else {
      if (isTouchScreen === null) {
        setIsTouchScreen(false);
      }
    }
    if (x && y) {
      setMousePosition({
        x: x,
        y: y
      });
    }
  }

  const handleMouseMoveThrottled = _.throttle(handleMouseMove, 60);

  return (
    <Content
      {...props}
      handleMouseMoveThrottled={handleMouseMoveThrottled}
      mousePosition={mousePosition}
      isTouchScreen={isTouchScreen}
      navigate={navigate}
      messageHistory={messageHistory}
      setMessageHistory={setMessageHistory}
      transcriptHistory={transcriptHistory}
      websocket={websocket}
      readyState={readyState}
      location={location}
      isLoading={isLoading}
      setIsLoading={setIsLoading}
      windowWidth={windowDimensions.windowWidth}
      windowHeight={windowDimensions.windowHeight}
      cookiesAreAccepted={cookiesAreAccepted}
      setCookiesAreAccepted={setCookiesAreAccepted}
      audio={audio}
      bufferLength={bufferLength}
      analyser={analyser}
      dataArray={dataArray}
      isPlaying={isPlaying}
      setIsPlaying={setIsPlaying}
      connectionStatus={connectionStatus}
      canvas={canvas}
      handleStreamStarted={handleStreamStarted}
    />
  );
}

export default App;
