const Transcript = (props) => {

  const { transcriptHistory } = props;

  return (
    <div className="transcript__wrapper">
      {
        transcriptHistory &&
        transcriptHistory[0] &&
        transcriptHistory.map(
          (item, i) => (
            <p key={i}>
              {item.text}
            </p>
          )
        )
      }
    </div>
  )
}

export default Transcript;