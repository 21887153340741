import { useRef, useState, Suspense } from 'react';
import { Canvas } from '@react-three/fiber';
import ThreeScene from './ThreeSceneFractal';

const ThreeCanvas = (props) => {

  const [isMobile] = useState(/iPhone|iPad|iPod|Android/i.test(navigator.userAgent));
  const camera = useRef();

  return (
    <Canvas
      // frameloop='demand'
      // camera={{ fov: 75, position: [0, 0, 2]}}
      // pixelRatio={Math.min(2, isMobile ? window.devicePixelRatio : 1)}
      // onCreated={({ gl }) => {
      //   gl.toneMapping = THREE.CineonToneMapping;
      //   gl.toneMappingExposure = 1.25;
      //   gl.outputEncoding = THREE.sRGBEncoding;
      //   // gl.alpha = true;
      //   // gl.setClearColor("white", 0);
      // }}
    >
      <ambientLight intensity={0.6} />
      <pointLight position={[150, 150, 150]} intensity={0.55} />
      <Suspense fallback={null}>
        <ThreeScene {...props} isMobile={isMobile} camera={camera} />
      </Suspense>
    </Canvas>
  )
}

export default ThreeCanvas;