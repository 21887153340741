const vertexShader = `
const mat4 modelViewProjectMatrixInverse = mat4 (
    0.8572106957435608,
    0.0,
    0.0,
    0.0,
    0.0,
    0.4142135679721832,
    0.0,
    0.0,
    0.0,
    0.0,
    -9.999998092651367,
    -4.999999046325684,
    0.0,
    0.0,
    32.000000953674316,
    5.0
);
// uniform mat4 modelViewProjectMatrixInverse;
varying vec3 rayDir;

void main() {
    gl_Position = vec4(position,1);
    vec4 WPpos = modelViewProjectMatrixInverse * gl_Position;
    WPpos /= WPpos.w;
    rayDir = WPpos.xyz - cameraPosition;
}`;

export default vertexShader;