import { useState } from 'react';
import { Link } from 'react-router-dom';
// import testAudio from '../../assets/testAudio/ebtgtest.mp3';

const Listen = (props) => {

  const { audio, handleStreamStarted, isPlaying } = props;
  const [src] = useState('https://d131.rndfnk.com/ard/dlf/06/mp3/128/stream.mp3?sid=23KV2uCA7oi4Nfkti3nIVs5OywJ&token=YReZwoMrJDaeLiGZFXWETYu2i-RgPsWznkO6_mzj2Y4&tvf=KunslFbLxxZkMTMxLnJuZGZuay5jb20');
  const [volume, setVolume] = useState(1);

  return (
    <div>
      <div className={`listen__wrapper${isPlaying === true ? ' playing' : ''}`}>
        <button
          className={`listen__button${isPlaying === true ? ' active' : ''}`}
          onClick={handleStreamStarted}
        >
          {
            isPlaying === false ?
            <svg
              className="listen__button__icon listen__button__icon--play"
              xmlns="http://www.w3.org/2000/svg"
              width="40.12"
              height="30"
              viewBox="3 0 40.12 30"
            >
              <g id="Polygon_1" data-name="Polygon 1" transform="translate(26.889 38.441) rotate(-150)">
                <path d="M13.8,2.94a2,2,0,0,1,3.45,0L29.282,23.45a2,2,0,0,1-1.725,3.012H3.492A2,2,0,0,1,1.767,23.45Z" stroke="none" />
                <path d="M 15.5244836807251 2.952449798583984 C 15.3522834777832 2.952449798583984 14.92352294921875 3.000579833984375 14.66195297241211 3.446439743041992 L 2.629583358764648 23.95616912841797 C 2.364843368530273 24.40744018554688 2.537723541259766 24.80792999267578 2.624343872070312 24.95916938781738 C 2.710964202880859 25.11042022705078 2.968914031982422 25.46218872070312 3.492103576660156 25.46218872070312 L 27.55686378479004 25.46218872070312 C 28.08005332946777 25.46218872070312 28.33800315856934 25.11042022705078 28.42462348937988 24.95916938781738 C 28.51124382019043 24.80792999267578 28.68412399291992 24.40744018554688 28.41939353942871 23.95616912841797 L 16.38701248168945 3.446449279785156 C 16.12543487548828 3.000589370727539 15.69668388366699 2.952449798583984 15.5244836807251 2.952449798583984 M 15.5244836807251 1.952455520629883 C 16.19380569458008 1.952455520629883 16.86312866210938 2.281780242919922 17.24953460693359 2.9404296875 L 29.28191375732422 23.45015907287598 C 30.0641040802002 24.78343963623047 29.10265350341797 26.46219062805176 27.55686378479004 26.46219062805176 L 3.492103576660156 26.46219062805176 C 1.946313858032227 26.46219062805176 0.98486328125 24.78343963623047 1.767053604125977 23.45015907287598 L 13.7994327545166 2.9404296875 C 14.18583869934082 2.281780242919922 14.85516166687012 1.952455520629883 15.5244836807251 1.952455520629883 Z" stroke="none" fill="#000" />
              </g>
            </svg>
            :
            <svg
              className="listen__button__icon listen__button__icon--pause"
              xmlns="http://www.w3.org/2000/svg"
              width="22"
              height="25"
              viewBox="0 0 22 25"
            >
              <g id="Group_6" data-name="Group 6" transform="translate(-1463 -78)">
                <g id="Rectangle_11" data-name="Rectangle 11" transform="translate(1463 78)" stroke="#000" strokeWidth="1">
                  <rect width="8" height="25" rx="2" stroke="none"/>
                  <rect x="0.5" y="0.5" width="7" height="24" rx="1.5"/>
                </g>
                <g id="Rectangle_12" data-name="Rectangle 12" transform="translate(1477 78)" stroke="#000" strokeWidth="1">
                  <rect width="8" height="25" rx="2" stroke="none"/>
                  <rect x="0.5" y="0.5" width="7" height="24" rx="1.5"/>
                </g>
              </g>
            </svg>
          }
        </button>
        <div className="listen__volume__wrapper">
          <input
            type="range"
            min="0"
            max="1"
            step="0.01"
            value={volume}
            onChange={(e) => {
              setVolume(e.target.value);
              if (audio.current) {
                audio.current.volume = e.target.value;
              }
            }}
            className="volume"
          />
        </div>
        <Link
          className="listen__link--info listen__button"
          to="/info"
        >
          Info
        </Link>
        {/* <audio ref={audio} crossOrigin="Anonymous" src={testAudio} preload='none' /> */}
        <audio
          ref={audio}
          crossOrigin="Anonymous"
          src={src}
          preload='none'
          volume={volume}
        />
      </div>
    </div>
  )
}

export default Listen;