import { useEffect } from 'react';

const CanvasGraph = (props) => {

  const { canvas, windowHeight, windowWidth } = props;

  useEffect(() => {
    if (windowHeight && windowWidth) {
      canvas.current.width = canvas.current.parentNode.offsetWidth;
      canvas.current.height = canvas.current.parentNode.offsetHeight;
    }
  }, [windowHeight, windowWidth, canvas]);

  return (
    <div className="visualiser-test__wrapper">
      <canvas className="visualiser-test" ref={canvas} width={60} height={40} />
    </div>
  )
}

export default CanvasGraph;