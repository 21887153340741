import { useState } from 'react';

const Chatbot = (props) => {

  const { messageHistory, setMessageHistory, websocket } = props;
  const [messageText, setMessageText] = useState('');

  const handleSubmit = (event) => {
    event.preventDefault();
    websocket.current.send(JSON.stringify({
      msg: "chat",
      text: messageText
    }));
    setMessageHistory(
      [
        ...messageHistory,
        {
          type: 'sent',
          text: messageText
        }
      ]
    );
    setMessageText('');
  }

  return (
    <div className="chatbot__wrapper">
      <form className="chatbot" onSubmit={handleSubmit}>
        <ul className="messages__list">
          {
            messageHistory &&
            messageHistory[0] &&
            messageHistory.map(
              (message, i) => (
                <li
                  key={i}
                  className={`messages__list__item messages__list__item--${message.type}`}
                >{message.text}</li>
              )
            )
          }
        </ul>
        <textarea className="chatbot__textarea" placeholder="Type here..." onChange={(e) => setMessageText(e.target.value)} value={messageText} />
        <input className="chatbot__submit button" type="submit" value="send" />
      </form>
    </div>
  )
}

export default Chatbot;