import { useRef, useEffect, useMemo } from "react";
import { useFrame } from "@react-three/fiber";
import * as THREE from "three";
import Camera from './Camera';
import lavaFragmentShader from './shaders/MandelbulbFragmentShader';
import lavaVertexShader from './shaders/MandelbulbVertexShader';


const LavaBall = (props) => {

  const material = useRef();

  const uniforms = useMemo(() => {
    return {
      "time": { value: 0.0 },
      "width": { value: 1200.0 },
      "height": { value: 1200.0 },
      "pixelSize": { value: 1.0 },
      "power": { value: 3.6 },
      "phase": { value: new THREE.Vector2(0.5, 0.5) },
      "bounding": { value: 3.5 },
      "max_iterations": { value: 6 },
      "bailout": { value: 2.0 },
      "diffuseColor": { value: new THREE.Vector4(0.2, 0.85, 0.99, 1.0) },
      "light": { value: new THREE.Vector3(1.0, 42.0, 38.0) },
      "backgroundColor": { value: new THREE.Vector4(1.0, 1.0, 0.4, 0.0) },
      "ambientColor": { value: new THREE.Vector4(0.99, 0.86, 0.6, 1.0) },
      "lightColor": { value: new THREE.Vector4(0.4, 0.09, 0.99, 0.0) },
      "eye": { value: new THREE.Vector3(20, 20, 0) },
      "specularity": { value: 0.99 },
      "rimLight": { value: 0.0 },
      "colorSpread": { value: 20.2 },
      "specularExponent": { value: 5.0 },
      "STEP_LIMIT": { value: 110 },
      "epsilonScale": { value: 1.0 },
      "modelViewProjectMatrixInverse": {
        value: new THREE.Matrix4().set(
          0.8572106957435608,
          0.0,
          0.0,
          0.0,
          0.0,
          0.4142135679721832,
          0.0,
          0.0,
          0.0,
          0.0,
          -9.999998092651367,
          -4.999999046325684,
          0.0,
          0.0,
          9.000000953674316,
          5.0
        )
      }
    }
  }, []);

  const mesh = useRef();

  useEffect(() => {
  }, []);

  useFrame(() => {
    if (material.current) {
      material.current.uniforms.time.value += 0.01;
      const time = material.current.uniforms.time.value;
      const phasex = 0.05, phasey = 1.05;
      material.current.uniforms.phase.value = new THREE.Vector2(phasex + time / 16.0, phasey - time / 16.0);
    }
  });

  const handleClick = () => {
    material.current.uniforms.time.value += 0.01;
    material.current.uniformsNeedUpdate = true;
    console.log(material.current.uniforms.time.value);
    console.log(material.current)
    material.current.uniforms.power.value = Math.random() * 11;
  }

  return (
    <mesh
      ref={mesh}
      onPointerDown={handleClick}
    >
      <planeBufferGeometry args={[48, 48]} />
      <shaderMaterial
        attach="material"
        ref={material}
        uniforms={uniforms}
        fragmentShader={lavaFragmentShader}
        vertexShader={lavaVertexShader}
        uniformsNeedUpdate={true}
        needsUpdate={true}
      />
    </mesh>
  );
}

const ThreeSceneFractal = (props) => {

  const camera = useRef();

  return (
    <group>
      <Camera camera={camera} />
      <color attach="background" args={["black"]} />
      <LavaBall {...props} camera={camera} />
    </group>
  )
}

export default ThreeSceneFractal;